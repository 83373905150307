// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'email',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'phone_number',
    label: '',
    Object: 'value',
    width: '150'
  },
  { prop: 'user_group', label: '', Object: 'value', width: '150' }
  ]
}
