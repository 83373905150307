<template>
  <div class="homeMsgOne">
    <div v-if="!isLoad">
        <header>
        <span>New Users</span>
        <el-select v-model="value" placeholder="Select Action" @change="changeData">
          <el-option label="Month To Date" value="Month To Date"></el-option>
        </el-select>
      </header>
      <main>{{userConst.new_user_count}}</main>
      <footer>
        <svg-icon icon-class="rise" class="rise" />
        <span> 125% Increase</span>
      </footer>
    </div>
    <el-skeleton v-else />
  </div>
</template>

<script>
import apis from '@/services'

export default {
  name: 'NewUsers',
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      value: 'Month To Date',
      isLoad: true,
      userConst: {}
    }
  },
  created () { this.init() },
  methods: {
    async init (params) {
      this.isLoad = true
      const res = await  apis.CrmUsers.getCrmStats(params)
      this.userConst = res.data
      this.isLoad = false
    },
    changeData (e) {
      this.init(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.homeMsgOne {
  .el-select {
    width: 150px;
  }
  display: flex;
  flex-direction: column;
  width: 380px;
  height: auto;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 16px 24px;
  header {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #666;
    display:flex;
    justify-content:space-between;
    span {
      line-height: 32px;
    }
  }
  main {
    justify-self: center;
    line-height: 50px;
    color: #6894D4;
    font-size: 36px;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  footer {
    .rise{
      color: green
    }
    span{
      font-size: 12px;
      font-weight: 600;
    }
  }
}
</style>
